import React, { useRef, useCallback, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { Grid, Button, Tooltip } from '@mui/material';
import { PrintOutlined } from '@mui/icons-material';
import { colorTheme } from "../../Common/Theme";
import InvoicePDF from './InvoicePDF';

interface PrintButtonsProps {
    customerId: any;
}

const PrintButton: React.FC<PrintButtonsProps> = ({ customerId }) => {
    const componentRef = useRef<HTMLDivElement>(null);
    const [isPrinting, setIsPrinting] = useState(false);

    const handlePrint = useCallback(() => {
        setIsPrinting(true);
    }, []);

    const handleAfterPrint = useCallback(() => {
        setIsPrinting(false);
    }, []);

    return (
        <>
            <Grid item>
                <ReactToPrint
                    trigger={() => (
                        <Tooltip placement='left' title="Print invoice">
                            <Button
                                sx={{
                                    padding: 0,
                                    border: 0,
                                    minWidth: '24px',
                                    color: colorTheme.palette.topLink,
                                    '&:hover': {
                                        border: 0,
                                        color: colorTheme.palette.indigo500
                                    },
                                    fontWeight: '600'
                                }}
                                variant="text"
                                disabled={isPrinting}
                            >
                                <PrintOutlined />
                            </Button>
                        </Tooltip>
                    )}
                    content={() => componentRef.current}
                    onBeforeGetContent={handlePrint}
                    onAfterPrint={handleAfterPrint}
                />
            </Grid>

            <div id="downloadInvoice" style={{ position: 'absolute', width: '794px', height: '559px', left: '-999%' }}>
                <InvoicePDF
                    ref={componentRef}
                    customerId={customerId}
                />
            </div>
        </>
    );
}

export default PrintButton;
import sdfLogo from '../../Assets/images/sdf-logo.png';
import shree from '../../Assets/images/shree.png';
import React from 'react';
import '../Billing/PDF.css';
import { useGetInvoiceDetailsQuery } from '../../Shared/Store/Api/invoiceApi';

interface InvoicePDFProps {
    customerId: any;
}

const InvoicePDF = React.forwardRef<HTMLDivElement, InvoicePDFProps>(
    ({ customerId }, ref) => {

        const { data: invoiceDetails, isLoading } = useGetInvoiceDetailsQuery(customerId);

        if (isLoading || !invoiceDetails) {
            return <div ref={ref}>Loading...</div>;
        }

        // const invoiceDates = invoiceDetails?.all_invoices || {};
        console.log("invoiceDates", invoiceDetails)
        // const invoiceProducts = invoiceDetails?.invoice_prod_details || {};

        const numberToWords = (num: number) => {
            const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
            const tens = ['', 'ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
            const teens = ['eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];

            let words = '';

            if (num === 0) {
                return 'zero';
            }
            if (num >= 1000) {
                words += numberToWords(Math.floor(num / 1000)) + ' thousand ';
                num %= 1000;
            }
            if (num >= 100) {
                words += ones[Math.floor(num / 100)] + ' hundred ';
                num %= 100;
            }
            if (num >= 20) {
                words += tens[Math.floor(num / 10)] + ' ';
                num %= 10;
            }
            if (num > 10 && num < 20) {
                words += teens[num - 11] + ' ';
                num = 0;
            }
            if (num > 0) {
                words += ones[num] + ' ';
            }
            return words.trim().toUpperCase();
        };


        return (
            <>
                <div className="table-outer" ref={ref}>
                    <table className='printPDFTable'>
                        <thead>
                            <tr>
                                <td className="logo" style={{ verticalAlign: 'middle' }}>
                                    <img src={sdfLogo} alt="SDF" width="141" height="45" />
                                    <p style={{ color: '#D13F80', fontFamily: 'Gabriola', fontSize: '15px', fontWeight: '400', whiteSpace: 'nowrap', padding: '5px 5px 0', lineHeight: '14px' }}>
                                        a venture of Gangaram Enterprises
                                    </p>
                                    <p style={{ color: '#53266A', fontFamily: 'Gabriola', fontSize: '15px', fontWeight: '400', whiteSpace: 'nowrap', padding: '5px 5px 0', lineHeight: '14px' }}>
                                        exclusive ladies gowns, kurtis, leggings, palazzos and  more
                                    </p>
                                    <p style={{
                                        color: '#000', fontSize: '12px', fontWeight: '500', whiteSpace: 'nowrap', padding: '5px 5px 0', lineHeight: '14px'
                                    }}>Contact no. : +91 7600070053</p>
                                    <p style={{ verticalAlign: 'bottom', color: '#F00', fontSize: '12px', fontWeight: '700', letterSpacing: '-0.081px', padding: '5px 5px 0' }}>
                                        GSTIN: 24AGBPA8175F1ZQ
                                    </p>
                                </td>
                                <td className="logo" style={{ textAlign: 'center' }}>
                                    <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={shree} alt="SDF" />
                                </td>
                                <td>
                                    <p style={{ fontSize: 13, color: '#000', textTransform: 'uppercase', fontWeight: 500, margin: '10px 0 0' }}>Retail Invoice</p>

                                    <p style={{
                                        color: '#000', fontSize: '12px', fontWeight: '700', verticalAlign: 'middle', margin: '5px 0 0'
                                    }}>Invoice no.: <span style={{ fontWeight: '400' }}>{invoiceDetails?.dt_sequence_no}</span></p>

                                    <p style={{
                                        color: '#000', fontSize: '12px', fontWeight: '700', verticalAlign: 'middle', margin: '5px 0 0'
                                    }}>Date : <span style={{ fontWeight: '400' }}>{invoiceDetails?.due_dt}</span></p>
                                    <p style={{
                                        color: '#000', fontSize: '12px', fontWeight: '700', verticalAlign: 'middle', margin: '5px 0 0'
                                    }}>Name : <span style={{ fontWeight: '400' }}>{invoiceDetails?.customer_name}</span></p>
                                    <p style={{
                                        color: '#000', fontSize: '12px', fontWeight: '700', verticalAlign: 'middle', margin: '5px 0 0'
                                    }}>Mobile : <span style={{ fontWeight: '400' }}>{invoiceDetails?.customer_contact}</span></p>
                                </td>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td colSpan={3}>
                                    <table className='productDetals'>
                                        <thead>
                                            <tr>
                                                <th className='text-left'>Sr. No.</th>
                                                <th className='text-left'>Description</th>
                                                <th className='text-left'>Size</th>
                                                <th className='text-center'>HSN</th>
                                                <th className='text-center'>Qty.</th>
                                                <th className='text-right'>MRP</th>
                                                <th className='text-right'>Price</th>
                                                <th className='text-right'>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoiceDetails.invoice_prod_details.length > 0 ? (
                                                invoiceDetails.invoice_prod_details.map((product: any, index: number) => (
                                                    <tr key={index}>
                                                        <td className='text-left'>{index + 1}</td>
                                                        <td>{product.prod_sku} ({product.product_title})</td>
                                                        <td>{product.size}</td>
                                                        <td className='text-center'>{product.hsn_code}</td>
                                                        <td className='text-center'>{product.quantity}</td>
                                                        <td className='text-right'>
                                                            {product.retail_mrp > 0 ? product.retail_mrp : product.unit_price}
                                                        </td>
                                                        <td className='text-right'>{product.unit_price}</td>
                                                        <td className='text-right'>{parseFloat(product.sub_total_disc || 0).toFixed(2)}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={6} className='text-center'>No products selected</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table >
                                </td >
                            </tr >
                        </tbody >
                        <tfoot>
                            <tr>
                                <td colSpan={3}>
                                    <table style={{ tableLayout: 'auto' }}>
                                        <tr><th colSpan={3} style={{ borderBottom: '1px solid #eee' }}>&nbsp;</th></tr>
                                        <tr className='amount-row'>
                                            <th style={{ textAlign: 'left', backgroundColor: 'transparent', lineHeight: 'normal' }}>Amount in words:&nbsp; {numberToWords(Math.round(parseFloat(invoiceDetails.sub_total)))} RS ONLY</th>
                                            <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal' }}>MRP total</th>
                                            <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal' }}>
                                                ₹ {parseFloat(invoiceDetails.total_mrp || 0).toFixed(2)}
                                            </th>
                                        </tr>
                                        <tr className='amount-row'>
                                            <th style={{ textAlign: 'left', backgroundColor: 'transparent', lineHeight: 'normal' }}></th>
                                            <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal' }}>Subtotal</th>
                                            <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal' }}>
                                                ₹ {parseFloat(invoiceDetails.sub_total || 0).toFixed(2)}
                                            </th>
                                        </tr>
                                        {parseFloat(invoiceDetails.disc_amt || 0) === 0
                                            ? <tr className='amount-row'>
                                                <th style={{ textAlign: 'left', backgroundColor: 'transparent', lineHeight: 'normal' }}></th>
                                                <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal' }}>&nbsp;</th>
                                                <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal' }}>
                                                    &nbsp;
                                                </th>
                                            </tr>
                                            :
                                            <tr className='amount-row'>
                                                <th style={{ textAlign: 'left', backgroundColor: 'transparent', lineHeight: 'normal' }}></th>
                                                <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal' }}>Discount</th>
                                                <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal' }}>
                                                    ₹ {parseFloat(invoiceDetails.disc_amt || 0).toFixed(2)}
                                                </th>
                                            </tr>
                                        }

                                        <tr className='amount-row'>
                                            <th style={{ textAlign: 'left', backgroundColor: 'transparent', lineHeight: 'normal' }}></th>
                                            <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal', fontSize: '12px' }}>Net amount</th>
                                            <th style={{ backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal', fontSize: '12px' }}>
                                                ₹ {Math.round(parseFloat(invoiceDetails.net_total || 0)).toFixed(2)}
                                            </th>
                                        </tr>
                                        <tr className='copyright'>
                                            <th style={{ paddingBottom: '8px', backgroundColor: 'transparent', textAlign: 'left', lineHeight: 'normal', fontSize: '12px', color: '#000' }}>Thank you for your purchase</th>
                                            <th style={{ paddingBottom: '8px', backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal', fontSize: '12px', color: '#4caf50' }}>You saved</th>
                                            <th style={{ paddingBottom: '8px', backgroundColor: 'transparent', textAlign: 'right', lineHeight: 'normal', fontSize: '12px', color: '#4caf50' }}>
                                                ₹ {((parseFloat(invoiceDetails.total_mrp || '0') - parseFloat(invoiceDetails.net_total || '0')).toFixed(2))}
                                            </th>
                                        </tr>
                                        <tr className='copyright'>
                                            <td>
                                                <p>Terms and Conditions</p>
                                                <ul>
                                                    <li>Subject to Vadodara jurisdiction.</li>
                                                    <li>Goods once sold will not be returned or exchanged.</li>
                                                    <li>Goods sold as is, not guarantee of work, color & material.</li>
                                                    <li>E. & O.E</li>
                                                </ul>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>SB- 28, Siddharth complex, Nr Express Hotel,<br />RC Dutt road, Alkapuri, Vadodara- 390007, Guj.
                                                <p style={{ textWrap: 'nowrap' }}>
                                                    <strong>info@shudhdesifashion.com, www.shudhdesifashion.com</strong>
                                                </p>
                                            </td>
                                            <td style={{ textAlign: 'center', verticalAlign: 'bottom', fontSize: '10px' }}>
                                                <p style={{ borderTop: '1px solid #eee', lineHeight: '5px' }}>&nbsp;</p>
                                                <p style={{ marginBottom: '5px' }}>Signature</p>
                                                <p><strong>For Gangaram Enterprise</strong></p>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </tfoot>
                    </table >
                    <footer></footer>
                    <div className='tableFooter'>
                        <table>
                        </table>
                    </div>
                </div >
            </>
        )
    });
export default InvoicePDF;
import { styled } from '@mui/material/styles';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    tableCellClasses,
    Chip,
    Grid,
    Link,
    Pagination,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Select,
    SelectChangeEvent,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    MenuItem,
    Tooltip,
    Button,
} from "@mui/material";
import { useGetInvoiceQuery } from "../../Shared/Store/Api/invoiceApi";
import { colorTheme, cardItem, plainAccordion, btnTheme } from "../../Common/Theme";
import { useEffect, useState } from 'react';
import { Search, VisibilityOutlined } from "@mui/icons-material";
import { ThemeProvider } from '@mui/material/styles';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import DynamicSearchBox from '../../Common/DynamicSearchBox';
import { useNavigate } from 'react-router-dom';
import PrintButton from './PrintButton'


const Status = {
    "": "",
    "paid": "Paid",
    "unpaid": "Unpaid",
    "pending": "Pending",
    "hold": "Hold",
};


const CustomerInvoicesTable = ({ setInvoiceDetailId }: any) => {
    const StyledTableCell = styled(TableCell)(() => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: colorTheme.palette.indigo400,
            color: '#fff',
            fontWeight: '600',
            padding: '8px'
        },
    }));

    const StyledTableRow = styled(TableRow)(() => ({
        '&:nth-of-type(odd)': {
            // backgroundColor: '#f4f4f4',
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
        'td': {
            padding: '8px'
        }
    }));


    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const [selectedStartDate, setSelectedStartDate] = useState(new Date(currentYear, currentMonth, 1).toISOString().split('T')[0]);
    const [selectedEndDate, setSelectedEndDate] = useState(new Date(currentYear, currentMonth + 1, 0).toISOString().split('T')[0]);
    const [activeMonth, setActiveMonth] = useState({ year: currentYear, monthIndex: 0 });
    const [expanded, setExpanded] = useState<string | false>('panel0');
    const navigate = useNavigate();

    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [search, setSearch] = useState("");
    const [contentPerPage, setContentPerPage] = useState('10');
    const [statusFilters, setStatusFilters] = useState<keyof typeof Status>("");

    const { data: invoices, refetch } = useGetInvoiceQuery({
        search: search, limit, offset, start_date: selectedStartDate,
        end_date: selectedEndDate, status: statusFilters,
    });

    useEffect(() => {
        refetch();
    }, [limit, offset, statusFilters, refetch]);

    const invoicesData = invoices?.results || [];
    const count = invoices?.count || 0;

    const handlePageChange = (event: any, value: any) => {
        setOffset((value - 1) * limit);
        refetch();
    };

    const handleSearch = (e: any) => {
        setOffset(0);
        setSearch(e.target.value);
        refetch();
    };

    const handleContentPerPageChange = (e: SelectChangeEvent) => {
        setContentPerPage(e.target.value as string);
        setLimit(parseInt(e.target.value, 10));
        setOffset(0);
        refetch();
    };

    const handleStatusChange = (newStatusFilter: typeof statusFilters) => {
        setStatusFilters(newStatusFilter);
        setOffset(0);
        refetch();
    }

    const getMonthNames = (year: number, currentYear: number, currentMonth: number) => {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        if (year === currentYear) {
            const months = monthNames.slice(0, currentMonth + 1).reverse();
            if (months.length > 0) months[0] = "This month";
            if (months.length > 1) months[1] = "Last month";
            return months;
        } else {
            return monthNames.reverse();
        }
    };

    const handleMonthClick = (year: number, monthIndex: number) => {
        let actualMonthIndex;
        if (year === currentYear) {
            actualMonthIndex = currentMonth - monthIndex;
        } else {
            actualMonthIndex = 11 - monthIndex;
        }

        const startDate = new Date(year, actualMonthIndex, 2).toISOString().split('T')[0];
        const endDate = new Date(year, actualMonthIndex + 1, 1).toISOString().split('T')[0];
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
        setActiveMonth({ year, monthIndex });
    };

    const fourYears = [];
    for (let index = 0; index < 4; index++) {
        fourYears.push(currentYear - index);
    }

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    const setInvoiceDetailsID = (e: any) => {
        setInvoiceDetailId(e);
    }

    const exchangeProduct = () => {
        navigate("/exchange");
    }
    const returnProduct = () => {
        navigate("/return");
    }

    return (
        <>
            <section id="main" className="flex flex-col overflow-auto flex-1 p-3 gap-3">
                <Grid container spacing={2} flexGrow={1}>
                    <Grid item xs={2}>
                        <Box sx={{ ...cardItem, gap: '10px' }}>
                            <ThemeProvider theme={plainAccordion}>
                                {
                                    fourYears.map((year, index) => (
                                        <Accordion defaultExpanded={index === 0} key={index} expanded={expanded === `${"panel" + index}`} onChange={handleChange(`${"panel" + index}`)}>
                                            <AccordionSummary expandIcon={<KeyboardArrowDownOutlined />}
                                                aria-controls={`${'panel' + index + '-content'}`}
                                                id={`${'panel' + index + '-header'}`}
                                            >
                                                {year}
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container flexDirection="column" spacing={2}>
                                                    {getMonthNames(year, currentYear, currentMonth).map((month, monthIndex) => (
                                                        <Link
                                                            href="#"
                                                            key={`${year}-${month}-${monthIndex}`}
                                                            className={`${activeMonth.year === year && activeMonth.monthIndex === monthIndex ? 'active' : ''}`}
                                                            onClick={() => handleMonthClick(year, monthIndex)}
                                                        >
                                                            {month}
                                                        </Link>
                                                    ))}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))
                                }
                            </ThemeProvider>
                        </Box>
                    </Grid>
                    <Grid item xs={10}>
                        <Box sx={cardItem}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={4}>
                                    <DynamicSearchBox>
                                        <FormControl variant="outlined" sx={{ borderColor: '#bbb', width: '100%' }} size='small'>
                                            <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                                            <OutlinedInput
                                                value={search}
                                                onChange={handleSearch}
                                                type='text'
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton>
                                                            <Search />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Search"
                                            />
                                        </FormControl >
                                    </DynamicSearchBox>
                                </Grid>
                                <Grid item xs={5} sx={{ marginLeft: 'auto' }}>
                                    <div className='flex items-center gap-2'>
                                        <Grid item>
                                            <ThemeProvider theme={btnTheme}>
                                                <Button
                                                    onClick={() => {
                                                        exchangeProduct();
                                                    }}
                                                    variant="contained">Exchange</Button>
                                            </ThemeProvider>
                                        </Grid>
                                        <Grid item>
                                            <ThemeProvider theme={btnTheme}>
                                                <Button
                                                    onClick={() => {
                                                        returnProduct();
                                                    }}
                                                    variant="contained">Return</Button>
                                            </ThemeProvider>
                                        </Grid>
                                        <DynamicSearchBox>
                                            <FormControl fullWidth variant="outlined" size='small'>
                                                <InputLabel id="status-label">Select status</InputLabel>
                                                <Select
                                                    labelId="status-label"
                                                    id="status"
                                                    value={statusFilters}
                                                    label="Select status"
                                                    onChange={(e) => handleStatusChange(e.target.value as typeof statusFilters)}
                                                >
                                                    {Object.entries(Status).map(([status, label]) => (
                                                        <MenuItem key={status} value={status}>
                                                            {label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth variant="outlined" size='small'>
                                                <InputLabel id="content-per-page-label">Content per page</InputLabel>
                                                <Select
                                                    labelId="content-per-page-label"
                                                    id="content-per-page"
                                                    value={contentPerPage}
                                                    label="Content per page"
                                                    onChange={handleContentPerPageChange}
                                                >
                                                    <MenuItem value={10}>10</MenuItem>
                                                    <MenuItem value={20}>20</MenuItem>
                                                    <MenuItem value={30}>30</MenuItem>
                                                    <MenuItem value={40}>40</MenuItem>
                                                    <MenuItem value={50}>50</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </DynamicSearchBox>
                                    </div>
                                </Grid>
                            </Grid>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Invoice No</StyledTableCell>
                                        <StyledTableCell>Customer code</StyledTableCell>
                                        <StyledTableCell>Customer</StyledTableCell>
                                        <StyledTableCell>Contact</StyledTableCell>
                                        <StyledTableCell>Date</StyledTableCell>
                                        <StyledTableCell>Net total</StyledTableCell>
                                        <StyledTableCell>Paid amount</StyledTableCell>
                                        <StyledTableCell>Unpaid amount</StyledTableCell>
                                        <StyledTableCell align="center">Status</StyledTableCell>
                                        <StyledTableCell align="right">&nbsp;</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invoicesData && invoicesData.length > 0 ? (
                                        invoicesData.map((result, index) => (
                                            <StyledTableRow key={index}>
                                                <TableCell>{result.dt_sequence_no}</TableCell>
                                                <TableCell>{result.customer_code}</TableCell>
                                                <TableCell>{result.customer_name}</TableCell>
                                                <TableCell>{result.customer_contact}</TableCell>
                                                <TableCell>{result.invoice_dt}</TableCell>
                                                <TableCell>{result.net_total.toFixed(2)}</TableCell>
                                                <TableCell>{result.paid_amt.toFixed(2)}</TableCell>
                                                <TableCell>{result.unpaid_amt.toFixed(2)}</TableCell>
                                                <TableCell align="center">
                                                    <Chip label={result.status} size="small" variant="outlined"
                                                        sx={{
                                                            minWidth: '60px',
                                                            fontSize: '10px',
                                                            textTransform: 'uppercase',
                                                            fontWeight: '600',
                                                            letterSpacing: '0.5px',
                                                            lineHeight: '14px',
                                                            borderWidth: '1px',
                                                            borderStyle: '1px solid',
                                                            backgroundColor: result.status == "Paid" ? colorTheme.palette.green50 : result.status == "Unpaid" ? colorTheme.palette.red50 : result.status == "Pending" ? colorTheme.palette.grey50 : colorTheme.palette.amber50,
                                                            borderColor: result.status == "Paid" ? colorTheme.palette.green500 : result.status == "Unpaid" ? colorTheme.palette.red500 : result.status == "Pending" ? colorTheme.palette.grey500 : colorTheme.palette.amber500,
                                                            color: result.status == "Paid" ? colorTheme.palette.green500 : result.status == "Unpaid" ? colorTheme.palette.red500 : result.status == "Pending" ? colorTheme.palette.grey500 : colorTheme.palette.amber500,
                                                        }}
                                                    />

                                                </TableCell>
                                                <TableCell align="right">
                                                    <Grid container spacing={1} justifyContent="center">
                                                        <Grid item>
                                                            <Tooltip placement='left' title="View invoice">
                                                                <Button
                                                                    sx={{ padding: 0, border: 0, minWidth: '24px', color: colorTheme.palette.topLink, '&:hover': { border: 0, color: colorTheme.palette.indigo500 }, fontWeight: '600' }}
                                                                    variant="text"
                                                                    onClick={() => {
                                                                        setInvoiceDetailsID(result.dt_sequence_no);
                                                                    }}
                                                                >
                                                                    <VisibilityOutlined />
                                                                </Button>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item>
                                                            <PrintButton
                                                                customerId={result.dt_sequence_no}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </StyledTableRow>
                                        ))
                                    ) : (
                                        <StyledTableRow>
                                            <TableCell colSpan={7}>No invoices found</TableCell>
                                        </StyledTableRow>
                                    )}
                                </TableBody>
                            </Table>
                            <Grid container justifyContent="end" sx={{ marginTop: 'auto' }}>
                                <Pagination
                                    count={Math.ceil(count / limit)}
                                    page={offset / limit + 1}
                                    onChange={handlePageChange}
                                />
                            </Grid>
                        </Box>
                    </Grid>
                </Grid >
            </section>
        </>
    );
};

export default CustomerInvoicesTable;